<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Sale Types") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-6"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Sale Types')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" icon v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  v-model="filterByEffective"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn v-if="$privilege.hasPrivilege('SALE_TYPE_CREATE')" fab small :to="{ name: 'saleType', params: { id: 0 } }">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
      >
        <template v-slot:item.saleTypeKey="{ item }">
          <router-link :to="{ name: 'saleType', params: { id: item.id } }">
            {{ item.saleTypeKey }}
          </router-link>
        </template>
        <template v-slot:item.effective="{ item }">
          <v-chip color="primary" v-if="item.effective">{{ $i18n.translate("Active") }}</v-chip>
          <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
        </template>
        <template v-slot:item.updatedDate="{ item }">
          {{ item.updatedDate | formatDateFromNow }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SaleTypes",
  metaInfo: {
    title: "Sale Types"
  },
  data() {
    return {
      loading: false,
      items: [],
      nameFilter: "",
      search: "",
      filterByEffective: "Active",
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      headers: [
        {
          value: "saleTypeKey",
          text: "Key",
          sortable: true
        },
        {
          value: "name",
          text: "Name",
          sortable: true
        },
        {
          value: "description",
          text: "Description",
          sortable: true
        },
        {
          value: "effective",
          text: "Status",
          align: "center"
        },
        {
          value: "updatedDate",
          text: "Last Updated",
          sortable: true
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.search && this.search.trim().length > 0) {
        filters.keyword = this.search.trim();
      }

      filters.isEffective = this.filterByEffective;

      this.$api
        .post(
          "/api/saleTypes/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    onClear() {
      this.search = "";
      this.getData();
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: this.$i18n.translate("Clients"),
        to: { name: "clients" },
        exact: true
      },
      {
        text: this.selectedClient.name,
        to: {
          name: "client",
          params: { id: this.selectedClient.id },
          exact: true
        }
      },
      {
        text: this.$i18n.translate("Programs"),
        to: { name: "programs" },
        exact: true
      },
      {
        text: this.selectedProgram.name,
        to: {
          name: "program",
          params: { id: this.selectedProgram.id },
          exact: true
        }
      },
      { text: this.$i18n.translate("Sale Types") }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"])
  }
};
</script>
